// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_4860 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 768px;

  @media only screen and (max-width: 990px) {
    min-height: 600px;
    max-height: 600px;
  }

  &:before {
    @include before(100%, 390px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 90%);
    top: 0;
    left: 0;

    @media only screen and (max-width: 990px) {
      display: none;
    }
  }

  &:after {
    @include before(408px, 100%);
    background: url(/i/design/gradient-right.png) repeat-y;
    top: 0;
    right: 0;

    @media only screen and (max-width: 990px) {
      display: none;
    }
  }

  .backstretch {
    position: relative;

    // @media only screen and (max-width: 990px) {
    //   position: fixed !important;
    //   height: 100vh !important;

    //   &:before {
    //     @include before(100%, 390px);
    //     background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 90%);
    //     top: 0;
    //     left: 0;
    //   }

    //   &:after {
    //     @include before(408px, 100%);
    //     background: url(/i/design/gradient-right.png) repeat-y;
    //     top: 0;
    //     right: 0;
    //   }
    // }

    &:before {
      @include before(1097px, 100%);
      background: url(/i/design/gradient-left.png) repeat-y;
      top: 0;
      left: 0;
    }

    &:after {
      @include before(100%, 295px);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 90%);
      bottom: 0;
      left: 0;
    }

    img {
      animation: zoom 30s ease forwards infinite;

      @keyframes zoom {
        from {
          transform: scale(1)
        }

        to {
          transform: scale(1.1)
        }
      }
    }
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 350px;
  z-index: 1;
}

.backstretch {
  position: fixed !important;
  height: 100vh !important;

  &:before {
    @include before(1097px, 100%);
    background: url(/i/design/gradient-left.png) repeat-y;
    top: 0;
    left: 0;
  }


  img {
    top: 0px !important;
    position: relative;

    &:after {
      @include before(100%, 390px);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 90%);
      top: 0;
      left: 0;
    }

    @media only screen and (max-width: 990px) {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  top: 160px;
  left: 320px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 50px;

  @media only screen and (max-width: 1660px) {
    left: 260px;
  }

  @media only screen and (max-height: 885px) and (min-width: 990px) {
    top: 140px;
  }

  @media only screen and (max-width: 990px) {
    left: 50%;
    transform: translateX(-50%);
    top: 350px;
  }

  @media only screen and (max-width: 600px) {
    top: 470px;
    width: 150px;
  }

}